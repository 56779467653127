import { type GlobalToken, theme } from "antd";
import { attachFieldTests, useFieldTests } from "./useFieldTests";
import { getGlobalPayments } from "./getGlobalPayments";
import { useEffect, useRef, useState } from "react";
import configuration from "utils/config";
import css2json from "css2json";
import payFieldsStyles from "./payFields.scss?inline";
import type { GlobalPaymentsCardForm } from "./payFields.types";

const { useToken } = theme;

/** Prefixes for environments that count as production. */
const productionEnvironmentPrefixes = ["stag", "prod"];

/** Use production PayFields when in production, otherwise use test. */
const payFieldsEnvironment = configuration.environment.hasAnyPrefix(productionEnvironmentPrefixes) ? "prod" : "test";

/** Standard configuration for the fields that we use. */
const payFieldsConfig = {
    "card-number": {
        target: "#card-number", // per-field-iframe will be rendered to this target
        placeholder: "•••• •••• •••• ••••",
    },
    "card-expiration": {
        target: "#card-expiration",
        placeholder: "MM / YYYY",
    },
    "card-cvv": {
        target: "#card-cvv",
        placeholder: "•••",
    },
    submit: {
        text: "Pay", // note text may be customized
        target: "#submit",
    },
};

/** Prepare the GPI PayFields form and components. */
export function useGlobalPaymentsForm(prepareForm: (form: GlobalPaymentsCardForm) => void) {
    const [fieldTestsPassed, dispatchFieldTest] = useFieldTests();
    const assertOnceRef = useRef(0);
    const globalPayments = getGlobalPayments();
    const payFieldsCss = usePayfieldStyles();

    useEffect(() => {
        if (++assertOnceRef.current > 1) throw new Error("PayFields form must not reconfigure");

        globalPayments.configure({
            "X-GP-Api-Key": configuration.payFieldsKey,
            "X-GP-Environment": payFieldsEnvironment,
        });

        const cardForm = globalPayments.ui.form({
            fields: payFieldsConfig,
            styles: payFieldsCss,
        });

        prepareForm(cardForm);

        attachFieldTests(cardForm, dispatchFieldTest);
    }, [globalPayments, prepareForm, dispatchFieldTest, payFieldsCss]);

    return fieldTestsPassed;
}

/** Map of CSS property to value */
type CssProperties = Record<string, string>;

/** Map of CSS selector to properties */
type CssStyle = Record<string, CssProperties>;

/** Get the PayFields CSS styles. */
function usePayfieldStyles() {
    const { token } = useToken();

    // load the styles into a ref so they don't change on re-render
    const [payfieldStyles] = useState<CssStyle>(() => getPayfieldStyles(token));

    return payfieldStyles;
}

/** Use css2json to convert the PayFields CSS to a JSON object. */
function getPayfieldStyles(token: GlobalToken) {
    const payFieldsCss: CssStyle = css2json(payFieldsStyles) as CssStyle;

    // replace all CSS variables pulled from SCSS file with AntD token values
    for (const cssSelector of Object.keys(payFieldsCss)) {
        const properties = payFieldsCss[cssSelector]!;

        for (const cssProperty of Object.keys(properties)) {
            if (typeof properties[cssProperty] === "string") {
                properties[cssProperty] = replaceAntdCssVarsWithToken(token, properties[cssProperty]);
            }
        }
    }

    return payFieldsCss;
}

/** Replace AntD CSS variables in a string with token values */
function replaceAntdCssVarsWithToken(token: GlobalToken, cssValue: string) {
    return cssValue
        .replace(/var\(--ant-([a-z-]+)\)/g, (_, variable: string) => {
            // transform to pascalCase
            const prop = variable.replace(/-([a-z])/g, (g: string) => g[1]?.toUpperCase() ?? "");

            if (varCache.has(prop)) {
                return varCache.get(prop)!;
            }

            const tokenValue = getPropOrThrow(token, prop);
            varCache.set(prop, tokenValue);
            return tokenValue;
        })
        .replaceAll(/[\r\n]\s+/g, " ");
}

/** Cache of CSS variables */
const varCache = new Map<string, string>();

function getPropOrThrow(token: GlobalToken, prop: string) {
    // most numeric items are pixels, but not some
    const notPixels = prop === "lineHeight";

    const val = token[prop as keyof typeof token];
    if (typeof val === "string") {
        return val;
    } else if (typeof val === "number") {
        return val.toString() + (notPixels ? "" : "px");
    }

    throw new Error("Token does not have a property called " + prop);
}
