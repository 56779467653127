import "./thanks.scss";
import { Button, type GetProps, Result } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { type DefaultReceiptContact, RequestChannel } from "api/responses";
import { ThanksPatientPortalBody } from "./ThanksPatientPortalBody";
import { usePayment } from "components/Payment/usePaymentContext";
import { usePaymentStatus } from "components/usePaymentStatusContext";
import type { StrictlyBasisPoints } from "utils/currency";

type ResultProps = GetProps<typeof Result>;

/**
 * Properties for "Thanks for your payment!" component.
 * Extends AntD ResultProps, so that in special cases, portions of the Result can be overridden.
 */
export interface ThanksProps extends Readonly<ResultProps> {
    readonly amount?: StrictlyBasisPoints;
    readonly detail?: string;
    readonly defaultReceiptContact: DefaultReceiptContact;
}

/**
 * Show "Thanks for your payment!" with various facts, using an antd Result.
 * Shown only during the original session in which the payment was made.
 */
export const Thanks = (props: Readonly<ThanksProps>) => {
    const { isPayNow } = usePayment();
    const { hideModal } = usePaymentStatus();

    const { defaultReceiptContact, detail } = props;

    const resultTitle = isPayNow ? "Payment Successful" : "Thanks for your payment!";

    const backButton = isPayNow ? (
        <Button type="primary" onClick={hideModal}>
            Back to Patients
        </Button>
    ) : null;

    return (
        <>
            <Result
                icon={<CheckCircleOutlined />}
                className="vyne-thanks-result"
                title={resultTitle}
                subTitle={
                    <ReceiptSentPrompt
                        isPayNow={isPayNow ?? false}
                        defaultReceiptContact={defaultReceiptContact}
                        detail={detail}
                    />
                }
                extra={backButton}
                {...props}
            />
            {!isPayNow && <ThanksPatientPortalBody amount={props.amount} />}
        </>
    );
};

interface ReceiptSentPromptProps {
    isPayNow: boolean;
    defaultReceiptContact: DefaultReceiptContact;
    detail?: string;
}

const ReceiptSentPrompt = ({ isPayNow, defaultReceiptContact, detail }: ReceiptSentPromptProps) => {
    let receiptSentFacts = {
        sentMessageType: "Payment notification",
        sentMessageContactForPractice: "patient",
        sentMessageContactForPatient: "you",
    };

    if (defaultReceiptContact.requestChannel === RequestChannel.SMS && defaultReceiptContact.phone) {
        receiptSentFacts = {
            sentMessageType: "Payment notification",
            sentMessageContactForPractice: defaultReceiptContact.phone,
            sentMessageContactForPatient: "your phone",
        };
    } else if (defaultReceiptContact.requestChannel !== RequestChannel.SMS && defaultReceiptContact.email) {
        receiptSentFacts = {
            sentMessageType: "Receipt",
            sentMessageContactForPractice: defaultReceiptContact.email,
            sentMessageContactForPatient: "your inbox",
        };
    }

    const receiptSentPrompt = `${receiptSentFacts.sentMessageType} successfully sent to ${isPayNow ? receiptSentFacts.sentMessageContactForPractice : receiptSentFacts.sentMessageContactForPatient}!`;

    return (
        <p>
            {receiptSentPrompt}
            {detail && isPayNow && (
                <>
                    <br />
                    {detail}
                </>
            )}
        </p>
    );
};
