import { SecurityScreen } from "./SecurityScreen/SecurityScreen";
import { useState } from "react";

export const StatementDetails = () => {
    const [hasVerifiedDob, setHasVerifiedDob] = useState(false);

    if (!hasVerifiedDob) {
        return (
            <SecurityScreen
                onVerifyDob={() => {
                    setHasVerifiedDob(true);
                }}
            />
        );
    }

    return <div>Statement Details</div>;
};
