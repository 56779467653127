import "./pageContentContainers.scss";
import { Flex, type GetProps } from "antd";

interface ContainerProps extends React.PropsWithChildren {
    readonly className?: string;
    readonly children: React.ReactNode;
}

export const FullPageContentContainer = ({ className, ...props }: ContainerProps) => (
    <PageContentContainer className={`navigation-page-container-full ${className ?? ""}`} {...props} />
);

export const NarrowPageContentContainer = ({ className, ...props }: ContainerProps) => (
    <PageContentContainer className={`navigation-page-container-narrow ${className ?? ""}`} {...props} />
);

const PageContentContainer = ({ className, ...props }: Readonly<GetProps<typeof Flex>>) => (
    <Flex vertical={true} gap="middle" className={`navigation-page-container ${className ?? ""}`} {...props} />
);
