import { isEqual, pick } from "radashi";
import { usePayment } from "components/Payment/usePaymentContext";
import { useReducer } from "react";
import { useSaleIntentPolling } from "./useSaleIntentPolling";
import { useStatusSync } from "./useStatusSync";
import { useStripeInitialContext } from "../useStripeInitialContext";
import type {
    SelectSavedEvent,
    SelectTerminalEvent,
    SetSaveCardEvent,
    SubmitUsingSavedEvent,
} from "components/Payment/Components/SavedCard/props";
import type {
    StripeConfirmedEvent,
    StripeErrorEvent,
    StripeEvent,
    StripeInitialState,
    StripeIntentEvent,
    StripeState,
    StripeStateCommon,
    StripeStateTypes,
    StripeSubmitClickedEvent,
} from "./stripeFlow.props";

/** Builds reducer that owns the state of a Stripe payment workflow. */
export function useStripeFlow() {
    const { patientId } = usePayment();
    const { intentId, clientSecret } = useStripeInitialContext();

    const [state, dispatch] = useReducer(
        reducer,
        Object.freeze({
            type: !intentId ? "initial" : "intent-polling",
            idempotencyPart: "", // will be generated at submitting state
            patientId,
            intentId,
            clientSecret,
            cardOnFileId: null,
            terminalId: null,
            saveCard: false,
        }),
    );

    useSaleIntentPolling(state, dispatch);
    useStatusSync(state);

    return [state, dispatch] as const;
}

// Intermediate state identifies the state we're transitioning to plus any changed common properties
type StripeStateIntermediate = StripeStateTypes & Partial<StripeStateCommon>;

// Common properties of the state that we can safely keep in any subsequent state. Note that properties like "error" are
// not included, so they do not accidentally propagate when we transition to another state.
const safeProps: (keyof StripeStateCommon)[] = [
    "patientId",
    "idempotencyPart",
    "intentId",
    "clientSecret",
    "cardOnFileId",
    "terminalId",
    "saveCard",
];

function reducer(prior: StripeState, action: StripeEvent) {
    // returns the prior state if nothing has changed
    const ifNotSame = (newState: StripeState) => (isEqual(newState, prior) ? prior : newState);

    // applies a portion of the prior state to the new state
    const safePrior = pick(prior, safeProps);
    const resolve = (newState: StripeStateIntermediate) => ifNotSame({ ...safePrior, ...newState });

    return Object.freeze(resolve(reducePartial(prior, action)));
}

// Note: explicit return type ensures we do not forget a case
function reducePartial(prior: StripeState, action: StripeEvent): StripeStateIntermediate {
    switch (action.type) {
        case "submit-clicked": // intentional fall-through!
        case "submit-using-saved":
            return reduceSubmit(prior, action);

        case "confirmed":
            return reduceConfirmed(prior, action);

        case "ingest-intent":
            return reduceIntent(prior, action);

        case "set-save-card":
            return reduceSetSavedCard(prior, action);

        case "select-saved-card":
            return reduceSelectSavedCard(prior, action);

        case "select-terminal":
            return reduceSelectTerminal(prior, action);

        case "error":
            return reduceErrorState(prior, action);

        case "reset":
            return { type: "initial" };
    }
}

const idempotency = { counter: 0 };

function reduceSubmit(prior: StripeState, action: StripeSubmitClickedEvent | SubmitUsingSavedEvent) {
    if (prior.type !== "initial") {
        return warnNonApplicable(prior, action);
    }

    // include new idempotencyPart for the submission that's about to happen
    return {
        type: "submitting",
        // passed to Vyne Payments API and then to Stripe API, helping to ensure idempotency
        idempotencyPart: `${new Date().getTime()}c${idempotency.counter++}`,
        amount: action.amount,
    } as const;
}

function reduceConfirmed(prior: StripeState, action: StripeConfirmedEvent) {
    if (prior.type !== "submitting") {
        return warnNonApplicable(prior, action);
    }

    // If we don't redirect, we need to start polling for intent status.
    // If we do redirect, go to a temporary terminal state that does nothing--Stripe will navigate the entire
    // page away and we'll restart from the beginning.
    const type = action.payNow ? "intent-polling" : "confirmed";
    return { type, intentId: action.intentId, clientSecret: action.clientSecret } as const;
}

function reduceIntent(prior: StripeState, action: StripeIntentEvent) {
    if (prior.type !== "intent-polling") {
        return warnNonApplicable(prior, action);
    }

    // NOTE "processing" status isn't explicitly handled here, therefore when we are "processing",
    // we remain in the intent-polling state, and SpinOrResultOr falls through to the WelcomeSpin case
    switch (action.intent.status) {
        case "succeeded":
            return {
                type: "ok",
                amount: action.intent.amount,
                defaultReceiptContact: action.intent.defaultReceiptContact,
            } as const;

        case "requires_payment_method":
            if (action.intent.lastPaymentError) {
                return { type: "error", error: action.intent.lastPaymentError, wasSubmitting: false } as const;
            } else {
                // this can happen if billing admin cancels card-not-present interaction
                console.log("requires_payment_method with no error");
                return { type: "initial" } as const;
            }

        case "canceled":
            return {
                type: "error",
                error: { type: "card_error", message: "Payment Canceled" },
                wasSubmitting: false,
            } as const;

        default: // processing, requires_payment_method_on_terminal, succeeded_wait_for_webhook
            return { ...prior, intentPollingStatus: action.intent.status };
    }
}

function reduceSetSavedCard(prior: StripeState, action: SetSaveCardEvent) {
    if (prior.type !== "initial") {
        return warnNonApplicable(prior, action);
    }

    return {
        ...prior, // state type remains the same
        saveCard: action.saveCard,
    };
}

function reduceSelectSavedCard(prior: StripeState, action: SelectSavedEvent) {
    if (prior.type !== "initial") {
        return warnNonApplicable(prior, action);
    }

    return {
        ...prior, // state type remains the same
        cardOnFileId: action.cardOnFileId,
        terminalId: null,
    } satisfies StripeState & StripeInitialState;
}

function reduceSelectTerminal(prior: StripeState, action: SelectTerminalEvent) {
    if (prior.type !== "initial") {
        return warnNonApplicable(prior, action);
    }

    return {
        ...prior, // state type remains the same
        cardOnFileId: null,
        terminalId: action.terminalId,
    } satisfies StripeState & StripeInitialState;
}

function reduceErrorState(prior: StripeState, action: StripeErrorEvent) {
    return {
        type: "error",
        error: action.error,
        intentId: action.intentId,
        clientSecret: action.clientSecret,
        wasSubmitting: prior.type === "submitting",
    } as const;
}

function warnNonApplicable(prior: StripeState, action: StripeEvent, msg?: string) {
    console.warn(`${action.type} not applicable in ${prior.type} ${msg}`.trim());
    return prior;
}
