import { useAuth } from "context/AuthContext";

/**
 * Get the enablement state of a feature flag.
 * @param flag The flag to look up.
 * @param defaultValue The default value to use if the flag does not exist.
 * @returns True if the flag is enabled, false otherwise.
 */
export function useFeatureFlag(flag: string, defaultValue = false) {
    const { featureFlags } = useAuth();
    return featureFlags[flag] ?? defaultValue;
}
