import { Button, Form, Input } from "antd";
import { validateEmail } from "utils/email";
import type { PatientReceiptMutator } from "api/clients/useReceipt";

export interface ReceiptFormProps {
    readonly defaultEmail?: string;
    readonly patientId: number;
    readonly mutate: PatientReceiptMutator;
    readonly isPending: boolean;
}

export const ReceiptForm = ({ defaultEmail, patientId, mutate, isPending }: ReceiptFormProps) => {
    const [emailForm] = Form.useForm();

    return (
        <Form
            layout="inline"
            name="receipt-form"
            initialValues={{
                email: defaultEmail,
                patientId,
            }}
            onFinish={mutate}
            form={emailForm}
        >
            <Form.Item
                name="email"
                required
                rules={[
                    {
                        required: true,
                        validator: emailValidator,
                    },
                ]}
            >
                <Input type="email" aria-label="Additional Receipt Email" placeholder="Enter Email Address" required />
            </Form.Item>

            <Form.Item name="patientId" hidden required>
                <Input type="hidden" />
            </Form.Item>

            <Form.Item shouldUpdate>
                {() => (
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isPending}
                        disabled={
                            !emailForm.isFieldTouched("email") ||
                            !!emailForm.getFieldsError().filter(({ errors }) => errors.length).length
                        }
                    >
                        Send
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
};

function emailValidator(_rule: unknown, value?: unknown) {
    return validateEmail(value) ? Promise.resolve() : Promise.reject(new Error("Invalid email address"));
}
