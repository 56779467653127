const localFormatNumeric = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
});

const localFormatNumericWithTime = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    hour12: true,
    minute: "2-digit",
});

const localFormatNumericWithTimePlus = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    hour12: true,
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "long",
});

export const defaultDateFormatString = "MM/DD/YYYY";

// Format of date-only over the API; matches old formatDateForApi
export const apiFormatString = "YYYY-MM-DD";

/**
 * Format date as `mm/dd/yyyy` or `mm/dd/yyyy, h:mm a/p`, or `mm/dd/yyyy, h:mm:ss a/p time zone`
 * @param date The date to be rendered to text.
 * @param includeTime When true, time is included. When "long", seconds and time zone are included.
 * @returns String containing the date, with numeric parts, conforming to en-us culture.
 */
export function formatDateNumerically(date: number | Date, includeTime?: boolean | "long") {
    return (
        includeTime === true
            ? localFormatNumericWithTime
            : includeTime === "long"
              ? localFormatNumericWithTimePlus
              : localFormatNumeric
    ).format(date);
}

const rxApiDate = /^\d{4}-\d{2}-\d{2}$/;

/**
 * Given a date string formatted as `yyyy-mm-dd`, provide date milliseconds that represent midnight local time, on that date
 * Given a date string formatted as `yyyy-MM-ddTHH:mm:ss.fffzzz`, provide date milliseconds that represent the point in time (UTC)
 * @param date Date string formatted as `yyyy-mm-dd` (perhaps a DateOnly fact from within an API response).
 * Alternatively, date string formatted as `yyyy-MM-ddTHH:mm:ss.fffzzz`.
 * @returns Date milliseconds that represent midnight local time on the provided date (or null if input was null),
 * or the point in time in UTC.
 */
export function parseApiDate(date: string | null) {
    if (!date) {
        return null;
    } else if (isApiDateWithTime(date)) {
        // input ex. "2022-02-18T18:25:18.450-04:00"
        return Date.parse(date);
    } else if (!rxApiDate.test(date)) {
        throw new Error(`incorrect parseApiDate format (${date})`);
    } else {
        // input ex. "2022-12-31"
        const fauxutc = new Date(Date.parse(date + "T00:00:00.000Z"));
        const year = fauxutc.getUTCFullYear();
        const month = fauxutc.getUTCMonth();
        const day = fauxutc.getUTCDate();
        const result = new Date(year, month, day, 0, 0, 0, 0);
        return result.getTime(); // Date milliseconds
    }
}

/**
 * Given a date string formatted as `yyyy-mm-dd`, return false
 * Given a date string potentially formatted as `yyyy-MM-ddTHH:mm:ss.fffzzz`, return true
 * @returns True if the date string provided is probably in `yyyy-MM-ddTHH:mm:ss.fffzzz` form
 */
export function isApiDateWithTime(date: string) {
    return date.length === 29;
}
