import "./SecurityScreen.scss";
import { Button, Flex, Form, type FormInstance, Result, Space, Typography } from "antd";
import { VyneForm } from "@vyne-shared/components/vyneForm/VyneForm";
import { VyneInputDatePicker } from "@vyne-shared/components/vyneInputs/vyneInputDatePicker/VyneInputDatePicker";
import { useDobFormSubmission } from "./useDobFormSubmission";
import { useState } from "react";
import dayjs from "dayjs";
import type { ValidatePatientDobRequest } from "api/requests";

/** Display the date of birth security screen modal dialog */
export const SecurityScreen = ({ onVerifyDob }: { onVerifyDob: () => void }) => {
    const [dobForm] = Form.useForm<ValidatePatientDobRequest>();
    const [error, setError] = useState("");

    const { isPending, isError, submit } = useDobFormSubmission(dobForm, {
        onError: () => setError("Date of Birth is incorrect."),
        onSuccess: (response) => {
            // If dob is invalid, set error message
            if (!response.success) {
                setError("Date of Birth is incorrect.");
                return;
            }

            onVerifyDob();
        },
    });

    return (
        <>
            {isError ? (
                <Result
                    status="500"
                    title="Unable to submit validation"
                    subTitle="There was a problem submitting date of birth. Contact support if this error persists."
                />
            ) : (
                <SecurityScreenContent
                    dobForm={dobForm}
                    error={error}
                    setError={setError}
                    submit={submit}
                    isPending={isPending}
                />
            )}
        </>
    );
};

interface SecurityScreenContentProps {
    /** Form instance for the security screen */
    readonly dobForm: FormInstance<ValidatePatientDobRequest>;

    /** Error message to display with input */
    readonly error: string;

    /** Set the error message */
    readonly setError: (error: string) => void;

    /** Submit the form */
    readonly submit: () => Promise<void>;

    /** Whether the form is pending submission */
    readonly isPending: boolean;
}

/** Display the content of the date of birth security screen */
const SecurityScreenContent = ({ dobForm, error, setError, submit, isPending }: SecurityScreenContentProps) => {
    return (
        <Space className="security-screen-content" direction="vertical" align="center">
            <Typography.Title level={2}>To view your statement, please verify your date of birth.</Typography.Title>
            <SecurityScreenForm dobForm={dobForm} error={error} setError={setError} />
            <p>
                If something isn't working as expected or you have any questions, we're here to help! Please don't
                hesitate to reach out to our office.
            </p>
            <SecurityScreenButtons
                onVerifyDob={() => {
                    setError("");
                    void submit();
                }}
                isPending={isPending}
            />
        </Space>
    );
};

interface SecurityScreenFormProps {
    /** Form instance for the security screen */
    dobForm: FormInstance<ValidatePatientDobRequest>;

    /** Error message to display with input */
    error: string;

    /** Set the error message */
    setError: (error: string) => void;
}

const SecurityScreenForm = ({ dobForm, error, setError }: SecurityScreenFormProps) => {
    const dobProps = error ? ({ validateStatus: "error", help: error } as const) : {};

    return (
        <VyneForm form={dobForm} name="patientSecurityScreen" requiredMark={false}>
            <Form.Item
                name="dateOfBirth"
                colon={false}
                label="Date of Birth"
                rules={[{ required: true, message: "Date of birth required." }]}
                required
                {...dobProps}
            >
                <VyneInputDatePicker
                    placeholder="MM/DD/YYYY"
                    mode="date"
                    format={{
                        format: "MM/DD/YYYY",
                        type: "mask",
                    }}
                    name="Date of Birth"
                    maxDate={dayjs()}
                    onChange={() => setError("")}
                />
            </Form.Item>
        </VyneForm>
    );
};

const SecurityScreenButtons = ({ onVerifyDob, isPending }: { onVerifyDob: () => void; isPending: boolean }) => (
    <Flex gap={12}>
        <Button type="link" href="/">
            Cancel
        </Button>
        <Button type="primary" onClick={onVerifyDob} loading={isPending}>
            Verify Statement Details
        </Button>
    </Flex>
);
