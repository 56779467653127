import "./vyneTable.scss";
import { Empty, type GetProps, type GetRef, Table, type TablePaginationConfig } from "antd";
import { VyneResultError } from "components/vyneResults/VyneResultError";
import { useCallback } from "react";
import type { AnyObject } from "antd/es/_util/type";

type TableProps<T extends AnyObject> = GetProps<typeof Table<T>>;
type TableRef<T extends AnyObject> = GetRef<typeof Table<T>>;

export interface VyneTableProps<T extends AnyObject>
    extends Readonly<Omit<TableProps<T>, "tableLayout" | "size" | "showSorterTooltip" | "bordered">> {
    readonly name: string;
    readonly erroring?: boolean;
    readonly errorMessage?: string;
    readonly bordered?: "none" | "partial" | "full";
}

/**
 * Override for the AntD {@link Table} component that applies common Vyne styles, improves accessibility, and adds
 * features.
 */
export const VyneTable = <T extends AnyObject>({
    bordered = "partial",
    className,
    columns,
    dataSource,
    erroring,
    errorMessage,
    locale,
    name,
    pagination,
    ...props
}: Readonly<VyneTableProps<T>>) => {
    // Force Our Own Type of Bordered
    let tableClass = "vyne-internal-table";
    let tableBordered: boolean | undefined = undefined;
    if (bordered === "partial") {
        tableClass += " vyne-internal-table-bordered";
    } else if (bordered === "full") {
        tableBordered = true;
    }

    // Force Pagination
    if (pagination !== undefined && pagination !== false) {
        const total = dataSource?.length ?? 0;

        pagination = applyVynePagination(pagination, total);
    }

    // Force React Node If String
    if (erroring === true) {
        dataSource = [];
        locale = {
            emptyText: <VyneTableErrorMessage message={errorMessage} />,
        };
    } else if (typeof locale?.emptyText === "string") {
        locale = {
            ...locale,
            emptyText: (
                <Empty
                    description={locale.emptyText.split("\n").map((x, i) => (
                        // eslint-disable-next-line @eslint-react/no-array-index-key -- empty text is always hard-coded so we're safe for now
                        <p key={`Empty-Line-${i}`}>{x}</p>
                    ))}
                />
            ),
        };
    }

    // Force Attributes Onto Headers
    if (columns) {
        columns = columns.map((column) => {
            const columnProps = { ...column };

            columnProps.onCell = (data, index) => {
                const onCell = column.onCell?.(data, index) ?? {};
                const headers = column.title?.toString();

                let rowKey = "key";
                switch (typeof props.rowKey) {
                    case "function":
                        rowKey = props.rowKey(data).toString();
                        break;
                    case "string":
                        rowKey = props.rowKey;
                        break;
                    case "number":
                        rowKey = props.rowKey.toString();
                }

                return {
                    ...onCell,
                    "aria-label": `${data[rowKey] ?? rowKey}-${headers}`,
                };
            };

            return columnProps;
        });
    }

    // Force Adds in Accessibility Caption and Class
    // Adding both Caption and Aria Label due to Aria Label having issue with some browsers and auto translate
    const tableRef = useCallback(
        (node: null | TableRef<T>) => {
            if (
                node !== null &&
                node.nativeElement.querySelector(".ant-table-content > table.vyne-internal-table")?.ariaLabel !== name
            ) {
                const tableSelector = node.nativeElement.querySelector(".ant-table-content > table");
                tableSelector?.setAttribute("class", `${tableSelector.getAttribute("class") ?? ""} ${tableClass}`);

                const captionSelector = node.nativeElement.querySelector(".ant-table-content > table > caption");
                if (captionSelector === null) {
                    tableSelector?.prepend(makeVyneTableCaption(name));

                    const summaryCaptionSelector = node.nativeElement.querySelectorAll(".ant-table-summary > tr");
                    if (summaryCaptionSelector.length > 0) {
                        summaryCaptionSelector.forEach((element, key) =>
                            element.setAttribute("aria-label", `${name} Summary Row ${key + 1}`),
                        );
                    }
                }
            }
        },
        [tableClass, name],
    );

    return (
        <Table
            {...props}
            aria-label={name}
            bordered={tableBordered}
            className={`vyne-table ${className ?? ""}`}
            columns={columns}
            dataSource={dataSource}
            locale={locale}
            pagination={pagination}
            ref={tableRef}
            showSorterTooltip={false}
            size="middle"
            tableLayout="fixed"
        />
    );
};

function applyVynePagination(pagination: TablePaginationConfig, total: number): TablePaginationConfig {
    const smallestPageOption = pagination.pageSizeOptions
        ? Number(pagination.pageSizeOptions[0])
        : pagination.defaultPageSize;

    const showPageSizeSelector = smallestPageOption !== undefined && smallestPageOption < total;

    return {
        ...pagination,
        hideOnSinglePage: pagination.hideOnSinglePage ?? !showPageSizeSelector,
        position: ["bottomRight"],
        showSizeChanger: pagination.showSizeChanger ?? showPageSizeSelector,
        showTotal: (total: number) => (total !== 1 ? `Total ${total} Items` : `Total ${total} Item`),
        total,
    };
}

function makeVyneTableCaption(name: string) {
    const caption = document.createElement("caption");

    caption.innerHTML = name;
    caption.setAttribute("class", "aria-invisible");

    return caption;
}

const VyneTableErrorMessage = ({ message }: { readonly message?: string }) => {
    const errorText = <p>{message ?? "There was a problem retrieving table data."}</p>;

    return <VyneResultError status="error" title="Unable To Load Table Data" subTitle={errorText} />;
};
