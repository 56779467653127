import "./paymentPage.scss";
import { Card, Descriptions, Flex, Skeleton, Space, Typography, theme } from "antd";
import { Link } from "react-router-dom";
import { PhoneTwoTone, PushpinTwoTone, ShopOutlined, SolutionOutlined } from "@ant-design/icons";
import { stringFromBasisPoints } from "@vyne-shared/utils/currency";
import { useAuth } from "context/AuthContext";
import { useFeatureFlag } from "utils/useFeatureFlags";
import { useStripeInitialContext } from "@vyne-shared/components/Payment/ProcessingPaymentFlow/Stripe/useStripeInitialContext";
import type { PatientPrepareSaleResult } from "@vyne-shared/api/responses";
import type { ProviderRolodexResponse } from "api/responses";

const { useToken } = theme;
const { Title } = Typography;

interface SummaryDataProps {
    readonly rolodex?: ProviderRolodexResponse;
    readonly sale?: PatientPrepareSaleResult;
}

/** Display a summary of the Provider and Statement details. */
export const SummaryData = ({ rolodex, sale }: SummaryDataProps) => (
    <Space direction="vertical" size="middle">
        <OfficeSummary rolodex={rolodex} />
        <StatementSummary sale={sale} />
    </Space>
);

const OfficeSummary = ({ rolodex }: { readonly rolodex?: ProviderRolodexResponse }) => {
    const { token } = useToken();

    return (
        <Card title={OfficeTitle} size="small">
            <Skeleton loading={!rolodex}>
                <Flex align="start" justify="flex-start" gap="small" className="contact-row">
                    {/* Header Here Keeps It Aligned With Address Without Complex CSS */}
                    <Title level={2}>
                        <PushpinTwoTone twoToneColor={token.colorPrimary} />
                    </Title>
                    <address>
                        <Title level={2}>{rolodex?.name}</Title>
                        {/* eslint-disable-next-line @eslint-react/no-array-index-key -- okay for this particular data */}
                        {rolodex?.address.map((address, i) => <span key={`address-${i}`}>{address}</span>)}
                    </address>
                </Flex>
                {rolodex?.phone ? (
                    <Flex align="start" justify="flex-start" gap="small" className="contact-row">
                        <PhoneTwoTone twoToneColor={token.colorPrimary} />
                        <span>{rolodex.phone}</span>
                    </Flex>
                ) : null}
            </Skeleton>
        </Card>
    );
};

const OfficeTitle = (
    <span>
        <ShopOutlined /> Office Details
    </span>
);

const StatementSummary = ({ sale }: { readonly sale?: PatientPrepareSaleResult }) => (
    <Card title={StatementTitle} size="small">
        <Skeleton loading={!sale}>{sale ? <StatementDetailsLink sale={sale} /> : null}</Skeleton>
    </Card>
);

const StatementDetailsLink = ({ sale }: { readonly sale: PatientPrepareSaleResult }) => {
    const balance = useTotalBalance(sale);
    const { hasDateOfBirth } = useAuth();
    const explanationOfBalance = !!useFeatureFlag("explanation-of-balance", false);

    return (
        <Descriptions column={1}>
            <Descriptions.Item label="Date">{sale.statementDate}</Descriptions.Item>
            <Descriptions.Item label="Balance">
                <b>{stringFromBasisPoints(balance)}</b>
            </Descriptions.Item>
            {explanationOfBalance && hasDateOfBirth ? (
                <Descriptions.Item>
                    <Link to="/statement-details" className="view-statement-details">
                        View Statement Details
                    </Link>
                </Descriptions.Item>
            ) : null}
        </Descriptions>
    );
};

const StatementTitle = (
    <span>
        <SolutionOutlined /> Statement Details
    </span>
);

function useTotalBalance(sale: PatientPrepareSaleResult) {
    const { intentId, balance } = useStripeInitialContext();

    if (intentId && balance) {
        // see useTotalBalance in ThanksPatientPortalBody.tsx
        return balance;
    }
    return sale.defaultBalance;
}
