import "./vyneFormItems.scss";
import { Card, Col, type GetProps, Row } from "antd";
import type { CSSProperties } from "react";

type ColProps = Readonly<GetProps<typeof Col>>;
type RowProps = Readonly<GetProps<typeof Row>>;

/**
 * A row within a form that generally contains two {@link ColHalfWidth}-wrapped fields.
 * We leave some space between the fields.
 */
export const RowTwoField = (props: RowProps) => <Row gutter={16} {...props}></Row>;

/**
 * A responsive column within a {@link RowTwoField} containing one field.
 *
 * Usually, the `span: 12` case is engaged, and the field takes up half of the full width.
 * On narrow screens, `span: 24` causes the fields to stack rather than to appear side by side.
 */
export const ColHalfWidth = (props: ColProps) => <Col xs={{ span: 24 }} sm={{ span: 12 }} {...props}></Col>;

interface FormItemGroupProps extends Readonly<React.PropsWithChildren> {
    readonly title: string;
    readonly id?: string;
    readonly className?: string;
    readonly useCard?: boolean;
    readonly style?: CSSProperties;
}

const WithCard = ({ useCard, title, children, className, ...props }: Readonly<FormItemGroupProps>) =>
    useCard ? (
        <Card className={`vyne-form-item-group vyne-form-item-group-card ${className ?? ""}`} size="small" {...props}>
            <fieldset>
                <legend>{title}</legend>
                {children}
            </fieldset>
        </Card>
    ) : (
        <fieldset className={`vyne-form-item-group vyne-form-item-group-non-card ${className ?? ""}`} {...props}>
            <legend>{title}</legend>
            {children}
        </fieldset>
    );

/** A set of fields grouped together under a heading */
export const FormItemGroup = ({ useCard = true, children, ...props }: Readonly<FormItemGroupProps>) => (
    <WithCard useCard={useCard} {...props}>
        {children}
    </WithCard>
);
