import { type AxiosError, isAxiosError } from "axios";
import { type BrowserOptions, browserTracingIntegration } from "@sentry/react";
import { captureConsoleIntegration, extraErrorDataIntegration } from "@sentry/browser";
import type { ConfigurationCommon } from "./config";
import type { Integration } from "@sentry/core";

/**
 * Generate shared Sentry configuration for UI code.
 * @param configuration The underlying Payments system configuration.
 * @param routingInstrumentation Optional instrumentation if react-router is being used.
 * @returns Partial Sentry configuration data. Caller must apply DSN and any other customization.
 */
export function sharedSentryConfig(configuration: ConfigurationCommon, routingInstrumentation?: Integration) {
    // Disable Sentry for local development, and also for the website monitoring system that causes problems.
    const disabled = configuration.environment.isDevelopment() || window.navigator.userAgent.endsWith("Site24x7");

    return {
        enabled: !disabled,
        environment: configuration.environment.environmentName,
        release: `${configuration.pkg}@${configuration.version}`,
        // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring when in QA, but
        // otherwise keep it something more reasonable to avoid flooding Sentry.
        tracesSampleRate: configuration.environment.is("QA") ? 1.0 : 0.1,
        tracePropagationTargets: ["localhost", /^\//, configuration.apiUrl],
        integrations: [
            routingInstrumentation ?? browserTracingIntegration(),
            captureConsoleIntegration({
                levels: ["warn", "error"],
            }),
            extraErrorDataIntegration(),
        ],
        // Helper to enhance Sentry events with additional information
        beforeSend(event, hint) {
            const exception = hint.originalException;

            // Include status codes in the fingerprint for HTTP exceptions so that different status codes produce
            // different Sentry issues. This allows us to track 400 Bad Request (caused by bad user data) separately
            // from 503 (caused by something failed in the system).
            if (isAxiosError(exception)) {
                event.fingerprint = createFingerprintFromAxiosError(exception);
            }

            return event;
        },
    } satisfies Partial<BrowserOptions>;
}

function createFingerprintFromAxiosError(exception: AxiosError) {
    const code = exception.code ?? "UNKNOWN";
    const status = exception.response?.status ?? exception.status ?? 0;
    return ["{{ default }}", code, status.toString()];
}
