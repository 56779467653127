import "./vyneForm.scss";
import { Form, type GetProps } from "antd";

type FormProps<T> = GetProps<typeof Form<T>>;

/** Override for the AntD {@link Form} component that applies common Vyne styles. */
export const VyneForm = <T,>({ children, className, layout, ...props }: Readonly<FormProps<T>>) => (
    <Form<T> {...props} className={`vyne-form ${className ?? ""}`} layout={layout ?? "vertical"}>
        {children}
    </Form>
);
