import type { StrictlyBasisPoints } from "utils/currency";
import type { StripeError } from "@stripe/stripe-js";

/** The types of payment processors supported by the system. */
export type PaymentProcessor = "GPI" | "Stripe";

/** Means of communication for a Payment Request. */
export enum RequestChannel {
    SMS = "SMS",
    Email = "Email",
    Both = "Both",
}

/** Settings for a Provider that are needed to make payments. */
export interface ProviderSettings {
    /** Are partial payments allowed? */
    allowPartialPayment: boolean;

    /** Explicitly identify which payment processor to use. */
    paymentProcessor: PaymentProcessor;

    /** Stripe Connected Account ID that will receive payments. */
    stripeConnectedAccountId?: string;
}

/**
 * The result of preparing to make a new card-not-present sale to a P/RP.
 * See Vyne.Payments.Lambda.Commands.PrepareReferenceIdResult.
 */
export interface PatientPrepareSaleResult extends WithBalances {
    /** The signed Reference ID to use for the new sale. */
    referenceIdToken: string;

    /** True if the Reference ID was newly-generated, false if an existing one was used. */
    created: boolean;

    /** Balance of the P/RP under collection, or estimated responsibility from PMS, in basis points.
     * Consider rename to fullBalance (expressive, consistent with useAmount, but note basis points vs. dollars inconsistency). */
    defaultBalance: StrictlyBasisPoints;

    /** Balance of the P/RP under collection, in basis points. For potential future use. */
    balance: StrictlyBasisPoints;

    /** Approximate P/RP statement date, in US culture format (MM/dd/yyyy) */
    statementDate: string;

    /** Provides a summary of each terminal (reader) that's available for use in card-present scenarios. */
    terminalSummaries?: TerminalSummaryResult[];

    /** Represents a PMS patient's total balance, and insurance estimate, if authorized and applicable. */
    pmsBalances?: PmsBalances;
}

/** In card-present scenarios, provides an identifier and label for a particular card reader. */
export interface TerminalSummaryResult {
    /** Identifies card reader for purposes including API calls. */
    id: string;

    /** Identifies card reader for humans. */
    label: string;
}

/** API Response (or other object) providing P/RP and PMS balances. */
export interface WithBalances {
    /** Balance of the P/RP under collection, in basis points. */
    balance?: StrictlyBasisPoints;

    /** Represents a PMS patient's total balance, and insurance estimate, if authorized and applicable. */
    pmsBalances?: PmsBalances;
}

/** Represents a PMS patient's total balance, and insurance estimate. */
export interface PmsBalances {
    /** Total ledger balance. */
    balanceTotal: StrictlyBasisPoints | null;

    /** Amount of the ledger balance expected to be covered by insurance. */
    insuranceTotalEstimate: StrictlyBasisPoints | null;

    /** The non-negative estimated patient responsibility. */
    balanceEstimated: StrictlyBasisPoints;

    /** Balance as-of date (in UTC; we'll format it to local). */
    balanceAsOf: number;
}

/** Represents a credit card stored on file for reuse. */
export interface CardOnFile {
    /** The internal ID of the card to use. */
    id: number;

    /** The brand of the card. */
    cardBrand: string;

    /** The last four digits of the card number. */
    cardLastFour: string;

    /** Whether the card is expired. */
    isExpired: boolean;
}

/**
 * Response of completing a card-not-present sale to a Patient/Responsible Party.
 * See Vyne.Payments.Lambda.Commands.PatientSaleResponse (not all fields mapped).
 */
export interface PatientSaleResponse {
    /** Information from the payment processor regarding the transaction. */
    processorResponse: string;

    /** Resolution of the sale (ex. approved, approved_csc_mismatch). */
    status: string;

    /** The amount transferred by the sale. */
    amount: StrictlyBasisPoints;

    /** P/RP contact information for receipt purposes, if applicable. */
    defaultReceiptContact: DefaultReceiptContact;
}

/** The result of creating a Stripe Payment Intent. */
export interface PatientSaleIntentResult {
    /** The ID of the Stripe Payment Intent. */
    intentId: string;

    /** The client secret used by Stripe to process the Payment Intent. */
    clientSecret: string;

    /**
     * The address of the Patient Portal to return to if redirection is necessary.
     *
     * Note that we attempt to disable any payment methods in Pay Now that would require redirection.
     */
    returnUrl: string;
}

/** The status of a Stripe Payment Intent. */
export type PatientSaleIntentStatus =
    | "succeeded"
    | "requires_payment_method"
    | "requires_payment_method_on_terminal" // custom Vyne status indicating that we're asking P/RP for card-present payment
    | "canceled"
    | "processing"
    | "succeeded_wait_for_webhook"; // custom Vyne status indicating we succeeded but want the Transaction written back

/** The result of checking the status of a Stripe Payment Intent. */
export interface PatientSaleIntentQueryResult {
    /** The ID of the Stripe Payment Intent. */
    intentId: string;

    /** The amount that was paid. */
    amount: StrictlyBasisPoints;

    /** The status of the Stripe Payment Intent. */
    status: PatientSaleIntentStatus;

    /**
     * The payment error encountered in the previous Payment Intent confirmation. It will be cleared if the Payment
     * Intent is later updated for any reason.
     */
    lastPaymentError?: StripeError;

    /** Identifies terminal (reader) associated with the Payment Intent.
     * Not really needed.
     */
    terminalId?: string;

    /** P/RP contact information for receipt purposes, if applicable. */
    defaultReceiptContact: DefaultReceiptContact;
}

/** P/RP contact information for receipt purposes. */
export interface DefaultReceiptContact {
    /** Phone number (for potential future use). */
    phone: string | null;

    /** Email address. */
    email: string | null;

    /** Patient Request Channel. */
    requestChannel: RequestChannel;
}

export type GetPatientCardsResponse = CardOnFile[];
