import { DataEntry } from "./DataEntry";
import { ErrorResult } from "./ErrorResult";
import { type PatientSaleResponse, RequestChannel } from "api/responses";
import { PostPaymentFlow } from "components/Payment/PostPaymentFlow";
import { WelcomeSpin } from "components/Payment/Components/WelcomeSpin";
import { theme } from "antd";
import { useSubmission } from "./useSubmission";
import type { PayFieldsEvent } from "./payFieldsFlow.events";
import type { PayFieldsState } from "./payFieldsFlow.state";
import type { SaleMutator } from "api/clients/useSale";

const { useToken } = theme;

interface PayFieldsFlowProps {
    readonly state: PayFieldsState;
    readonly dispatch: React.Dispatch<PayFieldsEvent>;
    readonly saleData?: PatientSaleResponse;
    readonly mutate: SaleMutator;
}

/** Display the different states of the GPI payment flow. */
export const PayFieldsFlow = ({ state, dispatch, saleData, mutate }: PayFieldsFlowProps) => {
    const { token } = useToken();
    useSubmission(state, dispatch, mutate);

    switch (state.type) {
        case "initial":
        case "tokenizing": // Need to keep GPI form mounted during tokenizing process
            return <DataEntry state={state} dispatch={dispatch} />;

        case "done": {
            const postPaymentFlowProps = saleData
                ? {
                      amount: saleData.amount,
                      detail: saleData.processorResponse ? saleData.processorResponse : saleData.status,
                      defaultReceiptContact: saleData.defaultReceiptContact,
                  }
                : {
                      defaultReceiptContact: {
                          phone: null,
                          email: null,
                          requestChannel: RequestChannel.Both,
                      },
                  };

            return <PostPaymentFlow {...postPaymentFlowProps} />;
        }

        case "error":
            return <ErrorResult state={state} dispatch={dispatch} />;

        default: // tokenized
            return <WelcomeSpin tip="Payment in progress&hellip;" color={token.colorPrimary} />;
    }
};
