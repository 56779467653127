import "./vyneInputDatePicker.scss";
import { Button, ConfigProvider, DatePicker, type GetProps, theme } from "antd";
import { defaultDateFormatString } from "utils/date";
import type { Dayjs } from "dayjs";

const { useToken } = theme;

type DatePickerProps = GetProps<typeof DatePicker<Dayjs>>;

interface VyneInputDatePickerProps extends Omit<DatePickerProps, "picker" | "cellRender" | "components"> {
    readonly name: string;
}

/** Override for the AntD {@link DatePicker} component that applies common Vyne styles and configuration. */
export const VyneInputDatePicker = ({
    allowClear,
    className,
    format,
    presets,
    showNow = true,
    ...props
}: Readonly<VyneInputDatePickerProps>) => {
    const { token } = useToken();

    // Force CSS of Presets
    if (presets) {
        presets = presets.map((preset) => ({
            label: <Button style={{ width: "100%", borderColor: token.colorInfo }}>{preset.label}</Button>,
            value: preset.value,
        }));
    }

    // Force Accessibility of Allow Clear
    if (allowClear) {
        allowClear = typeof allowClear === "boolean" ? allowClear : { clearIcon: <div /> };
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    DatePicker: {
                        cellHeight: 32,
                        cellWidth: 32,
                        cellHoverBg: "transparent",
                        activeBorderColor: token.colorBorder,
                        activeShadow: token.boxShadow,
                    },
                },
            }}
        >
            <DatePicker
                {...props}
                allowClear={allowClear}
                aria-label={props.name}
                cellRender={handleCellRender}
                className={`vyne-input-date-picker ${className ?? ""}`}
                format={format ?? defaultDateFormatString}
                picker="date"
                popupClassName="vyne-input-date-picker-popup"
                presets={presets}
                showNow={showNow}
            />
        </ConfigProvider>
    );
};

const handleCellRender: NonNullable<DatePickerProps["cellRender"]> = (current, info) => {
    if (info.type !== "date") {
        return info.originNode;
    }

    if (typeof current === "number" || typeof current === "string") {
        return <div className="ant-picker-cell-inner">{current}</div>;
    }

    return <div className="vyne-input-date-picker-cell ant-picker-cell-inner">{current.date()}</div>;
};
