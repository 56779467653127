import "./postPaymentFlow.scss";
import { Flex } from "antd";
import { ReceiptFlow } from "./ReceiptFlow";
import { Thanks, type ThanksProps } from "./Thanks";
import { usePayment } from "../usePaymentContext";
import type { DefaultReceiptContact } from "api/responses";

/** Properties for StripeThanks component. */
export interface PostPaymentFlowProps extends ThanksProps {
    readonly defaultReceiptContact: DefaultReceiptContact;
}

/**
 * Show "Thanks for your payment!" for Stripe-integrated providers, and provide a mechanism to send a receipt after the
 * successful payment.
 */
export const PostPaymentFlow = (props: Readonly<PostPaymentFlowProps>) => {
    // Grab Patient ID from Context
    const { patientId } = usePayment();

    return (
        <Flex vertical justify="center" align="center" gap={14} className="post-payment-screen">
            <Thanks {...props} />
            <ReceiptFlow patientId={patientId} {...props} />
        </Flex>
    );
};
