import { type UseMutationOptions, useMutation } from "@tanstack/react-query";
import { api } from "api";
import type { ValidatePatientDobResponse } from "api/responses";

export type PatientDobValidationOptions = UseMutationOptions<ValidatePatientDobResponse, unknown, string>;

/**
 * Client hook to validate a patient's Date of Birth.
 * @param options Options for the client call; provides callbacks for when the API call succeeds or fails.
 * @returns React Query mutation properties.
 */
export function usePatientDobValidation(options?: PatientDobValidationOptions) {
    return useMutation({
        ...options,
        mutationFn: async (dateOfBirth: string) => await api.statement.validateDob(dateOfBirth),
    });
}
