import { type GetProps, type GetRef, Select } from "antd";
import { useRef } from "react";

type SelectProps<T> = GetProps<typeof Select<T>>;
type SelectRef<T> = GetRef<typeof Select<T>>;

interface VyneSelectProps<T> extends Omit<SelectProps<T>, "virtual"> {
    // make aria-label required for accessibility
    readonly "aria-label": string;
}

/** Override for the AntD {@link Select} component that applies common Vyne styles. */
export const VyneSelect = <T,>({ dropdownStyle, getPopupContainer, style, ...props }: Readonly<VyneSelectProps<T>>) => {
    const selectRef = useRef<SelectRef<T>>(null);

    dropdownStyle = {
        ...(dropdownStyle ?? {}),
        minWidth: "fit-content",
    };

    style = {
        ...(style ?? {}),
        maxWidth: "fit-content",
    };

    // Workaround to deal with some issues with select in shadow root if not defined by user
    getPopupContainer ??= () => selectRef.current?.nativeElement.parentElement ?? document.body;

    return (
        <Select
            {...props}
            dropdownStyle={dropdownStyle}
            getPopupContainer={getPopupContainer}
            ref={selectRef}
            style={style}
            virtual={false}
        />
    );
};
