import "./vyneModal.scss";
import { type GetProps, Modal } from "antd";
import { VyneANTDProvider } from "components/vyneTheme/VyneThemeProvider";

export type VyneModalProps = GetProps<typeof Modal>;

/** Override for the AntD {@link Modal} component that applies common Vyne styles and ensures compatibility. */
export const VyneModal = ({
    children,
    className,
    getContainer,
    ...props
}: Readonly<React.PropsWithChildren<VyneModalProps>>) => (
    <Modal
        {...props}
        className={`vyne-modal ${className ?? ""}`}
        // Force container of Modal to be body unless set by user
        getContainer={getContainer ?? document.body}
        // Force render of content container with Config Provider for CSS
        // This is in case it renders outside of our default Provider
        modalRender={(children) => <VyneANTDProvider>{children}</VyneANTDProvider>}
    >
        {children}
    </Modal>
);
