import { PaymentPage } from "components/PaymentPage";
import { Route, Routes } from "react-router-dom";
import { StatementDetails } from "components/StatementDetails/StatementDetails";
import { withSentryReactRouterV6Routing } from "@sentry/react";

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export const Navigation = () => (
    <SentryRoutes>
        <Route path="/statement-details" element={<StatementDetails />} />
        <Route path="*" element={<PaymentPage />} />
    </SentryRoutes>
);
