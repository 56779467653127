import "./paymentPage.scss";
import { ErrorBoundary } from "@vyne-shared/components/ErrorBoundary";
import { Flex, Spin, Typography } from "antd";
import { NarrowPageContentContainer } from "@vyne-shared/components/vyneNavigation/PageContentContainers";
import { Payment } from "@vyne-shared/components/Payment";
import { SummaryData } from "./SummaryData";
import { useAuth } from "context/AuthContext";
import { useGetPatientCards } from "@vyne-shared/api/clients/useGetPatientCards";
import { usePrepareSale } from "@vyne-shared/api/clients/usePrepareSale";
import { useProviderRolodex } from "api/clients/useProviderRolodex";

const { Title } = Typography;

/**
 * The Payment page for the Patient Portal, where a P/RP can make a payment.
 *
 * Currently this is the only page the Patient Portal supports; there is no navigation.
 */
export const PaymentPage = () => {
    const auth = useAuth();
    const { data: sale, saleLoading, invalidatePrepareSale } = usePrepareSale(auth.patientId);
    const { data: rolodex, isPending: rolodexLoading } = useProviderRolodex();
    useGetPatientCards(auth.patientId, true); // pre-fetch cards

    return (
        <NarrowPageContentContainer className="payment-page">
            <Flex vertical={true} gap={8} className="payment-page-prompt">
                <Title level={2}>
                    Welcome, {auth.patientName}, to {auth.customerName}!
                </Title>
                <Title level={3}>Please pay your balance below.</Title>
            </Flex>
            <Flex justify="center" gap="large" flex-wrap="wrap" className="payment-section">
                <SummaryData rolodex={rolodex} sale={sale} />
                <Spin spinning={saleLoading || rolodexLoading} style={{ margin: "auto" }}>
                    {/* wrapper spin ensures payment components lay out as one group */}
                    {/* inner ErrorBoundary keeps payment errors constrained so the entire UI doesn't go
                     * away when there's a card error (mostly a GPI issue--we should remove the `throw` from
                     * its ErrorResult component) */}
                    {sale && (
                        <ErrorBoundary resettable onError={invalidatePrepareSale}>
                            <Payment
                                key={sale.referenceIdToken}
                                patientId={auth.patientId}
                                prepared={sale}
                                settings={auth.settings}
                                token={auth.token}
                            />
                        </ErrorBoundary>
                    )}
                </Spin>
            </Flex>
        </NarrowPageContentContainer>
    );
};
