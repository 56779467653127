import { type PatientDobValidationOptions, usePatientDobValidation } from "api/clients/usePatientDobValidation";
import { apiFormatString } from "@vyne-shared/utils/date";
import dayjs from "dayjs";
import type { FormInstance } from "antd";
import type { ValidatePatientDobRequest } from "api/requests";

/**
 * Build a Patient Update Request to submit to the API.
 * @param dobForm The form containing user data to submit.
 * @returns Request data, or undefined if the form is not ready for submission.
 */
async function buildRequest(dobForm: FormInstance<ValidatePatientDobRequest>) {
    try {
        // Perform validation first - this may throw an error if fields are invalid, which is fine.
        return await dobForm.validateFields();
    } catch {
        // If validation fails, it will display error messages for the user, so we don't need to do anything else.
        return undefined;
    }
}

/**
 * Hook to submit a Date of Birth form for validation.
 * @param dobForm The form to obtain data from.
 * @param options Options to configure the submission behavior.
 * @returns React Query mutation properties, but with a submit function instead of a mutate function.
 */
export function useDobFormSubmission(
    dobForm: FormInstance<ValidatePatientDobRequest>,
    options: Omit<PatientDobValidationOptions, "mutationFn">,
) {
    const { mutate, isError, isPending } = usePatientDobValidation(options);

    const submit = async () => {
        const request = await buildRequest(dobForm);
        if (request) {
            mutate(dayjs(request.dateOfBirth).format(apiFormatString));
        }
    };

    return { isPending, isError, submit } as const;
}
