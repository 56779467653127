import "./vyneInputRadio.scss";
import { ConfigProvider, Flex, type GetProps, Radio, theme } from "antd";

const { useToken } = theme;

type RadioProps = GetProps<typeof Radio>;

interface VyneInputRadioProps extends Readonly<RadioProps> {
    /* Details to display next to the radio input */
    readonly details?: React.ReactElement;

    /* Whether to remove the details section from the DOM */
    readonly removeDetails?: boolean;

    /* Whether to hide the details section visually with CSS */
    readonly hideDetails?: boolean;

    /* Type of radio input which controls the colors of the radio element */
    readonly type?: "default" | "warning" | "error";
}

/** Override for the AntD {@link Radio} component that applies common Vyne styles and also adds several features. */
export const VyneInputRadio = ({
    className,
    details,
    hideDetails,
    removeDetails,
    type = "default",
    ...props
}: Readonly<VyneInputRadioProps>) => {
    const { token } = useToken();

    // Force CSS
    const flexClassName = `vyne-input-radio ${hideDetails ? "vyne-input-radio-hide-details" : ""}`;

    const typeColors = {
        default: token.colorPrimary,
        warning: token.colorWarning,
        error: token.colorError,
    };

    return (
        <Flex vertical={true} className={flexClassName} gap="small">
            <ConfigProvider
                theme={{
                    components: {
                        Radio: {
                            wireframe: true,
                            colorPrimary: typeColors[type],
                        },
                    },
                }}
            >
                <Radio {...props} className={`vyne-input-radio-btn ${className ?? ""}`} />
            </ConfigProvider>
            {details !== undefined && !removeDetails ? <div className="vyne-input-radio-details">{details}</div> : null}
        </Flex>
    );
};
